// import React, { useRef, useEffect, useState } from "react"
import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { graphql, Link } from "gatsby"

// import mapboxgl from "!mapbox-gl"
// import "mapbox-gl/dist/mapbox-gl.css"

// import { createMachine, assign } from "xstate"
// import { useMachine } from "@xstate/react"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Container from "../components/Container"
import Hero from "../components/Hero"
import { Button } from "../components/Button"

import { BlueBanner } from "../components/Banner"

import { LocationMarker, Phone } from "../components/Icon/"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

// import marker from "../images/marker.png"
// mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const ContactPage = ({
	data: {
		page,
		backgroundImage,
		locationHamburgImage,
		locationDenmarkImage,
		locationSingaporeImage,
		locationLondonImage,
		locationGreeceImage,
	},
}) => {
	// const mapContainer = useRef(null)
	// const map = useRef(null)
	// const [lng, setLng] = useState(0)
	// const [lat, setLat] = useState(0)
	// const [zoom, setZoom] = useState(4)
	// let reqAnim

	const officeLocations = {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [10.00714, 53.58547],
				},
				properties: {
					id: 1,
					className: "xl:order-1",
					state: "FLY_TO_HAMBURG",
					onMouseOverState: "CENTER_ON_HAMBURG",
					title: "Main Office Hamburg",
					address: "Hanseaticsoft GmbH<br />Poßmoorweg 2<br />22301 Hamburg<br />Germany",
					phone: "+49 40 349 700 10 210",
					image: getImage(locationHamburgImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [12.57554, 55.73346],
				},
				properties: {
					id: 2,
					className: "xl:order-1",
					state: "FLY_TO_DENMARK",
					onMouseOverState: "CENTER_ON_DENMARK",
					title: "Branch Office Denmark",
					address:
						"Lloyd's Register<br />Harbour House 2<br />Sundkrogsgade 19, 3rd Floor<br />2100 Copenhagen<br />Denmark",
					image: getImage(locationDenmarkImage),
					phone: "+45 6155 2982",
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [103.78448, 1.29113],
				},
				properties: {
					id: 3,
					className: "xl:order-3",
					state: "FLY_TO_SINGAPORE",
					onMouseOverState: "CENTER_ON_SINGAPORE",
					title: "Branch Office Singapore",
					address:
						"Lloyd's Register<br />9 North Buona Vista Drive<br />#02-01, Tower 1 The Metropolis<br />Singapore 138588",
					image: getImage(locationSingaporeImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [-0.07994, 51.51218],
				},
				properties: {
					id: 4,
					className: "xl:order-3",
					state: "FLY_TO_LONDON",
					onMouseOverState: "CENTER_ON_LONDON",
					title: "Branch Office London",
					address:
						"Lloyd's Register<br />71 Fenchurch St<br />London EC3M 4BS<br />United Kingdom",
					image: getImage(locationLondonImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [-0.07994, 51.51218],
				},
				properties: {
					id: 4,
					className: "xl:order-3",
					state: "FLY_TO_GREECE",
					onMouseOverState: "CENTER_ON_GREECE",
					title: "Branch Office Greece",
					address:
						"Hellenic Lloyd's S.A.<br />348 Syggrou Avenue<br />17674, Kallithea, Athens<br />Greece",
					image: getImage(locationGreeceImage),
				},
			},
		],
	}

	// useEffect(() => {
	// 	if (map.current) return // initialize map only once
	// 	map.current = new mapboxgl.Map({
	// 		container: mapContainer.current,
	// 		style: "mapbox://styles/gordonheger/cl1w7fdas005m15jqgtbh4yxj",
	// 		center: [lng, lat],
	// 		zoom: zoom,
	// 		pitch: 45,
	// 	})

	// 	map.current.on("zoom", () => {
	// 		setZoom(map.current.getZoom())
	// 	})

	// 	map.current.on("load", () => {
	// 		map.current.loadImage(marker, (error, image) => {
	// 			if (error) throw error
	// 			map.current.addImage("custom-marker", image)

	// 			map.current.addSource("office-locations", {
	// 				type: "geojson",
	// 				data: officeLocations,
	// 			})

	// 			map.current.addLayer({
	// 				id: "office-locations-layer",
	// 				type: "symbol",
	// 				maxzoom: 15,
	// 				source: {
	// 					type: "geojson",
	// 					data: officeLocations,
	// 				},
	// 				layout: {
	// 					visibility: "visible",
	// 					"icon-image": "custom-marker",
	// 					"icon-padding": 3,
	// 					"icon-allow-overlap": true,
	// 				},
	// 			})

	// 			map.current.on("mouseenter", "office-locations-layer", () => {
	// 				map.current.getCanvas().style.cursor = "pointer"
	// 			})

	// 			// Change it back to a pointer when it leaves.
	// 			map.current.on("mouseleave", "office-locations-layer", () => {
	// 				map.current.getCanvas().style.cursor = ""
	// 			})

	// 			map.current.on("click", "office-locations-layer", e => {
	// 				const { id, title, address, image, phone, className, state } =
	// 					e.features[0].properties
	// 				send(state)
	// 			})
	// 		})
	// 	})
	// })

	// const [machine, send] = useMachine(
	// 	createMachine({
	// 		initial: "overview",
	// 		states: {
	// 			overview: {
	// 				on: {
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					CENTER_ON_HAMBURG: "centerOnHamburg",
	// 					CENTER_ON_DENMARK: "centerOnDenmark",
	// 					CENTER_ON_SINGAPORE: "centerOnSingapore",
	// 					CENTER_ON_LONDON: "centerOnLondon",
	// 				},
	// 				entry: () => {

	// 					send("CENTER_ON_HAMBURG")
	// 				},
	// 				exit: () => {},
	// 			},

	// 			centerOnHamburg: {
	// 				on: {
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 					CENTER_ON_DENMARK: "centerOnDenmark",
	// 					CENTER_ON_SINGAPORE: "centerOnSingapore",
	// 					CENTER_ON_LONDON: "centerOnLondon",
	// 					OVERVIEW: "overview",
	// 				},
	// 				entry: () => {
	// 					map.current.easeTo({ center: [10.00714, 53.58547] })
	// 				},
	// 			},
	// 			centerOnDenmark: {
	// 				on: {
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 					CENTER_ON_HAMBURG: "centerOnHamburg",
	// 					CENTER_ON_SINGAPORE: "centerOnSingapore",
	// 					CENTER_ON_LONDON: "centerOnLondon",
	// 					OVERVIEW: "overview",
	// 				},
	// 				entry: () => {
	// 					map.current.easeTo({ center: [12.57554, 55.73346] })
	// 				},
	// 			},
	// 			centerOnSingapore: {
	// 				on: {
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 					CENTER_ON_HAMBURG: "centerOnHamburg",
	// 					CENTER_ON_DENMARK: "centerOnDenmark",
	// 					CENTER_ON_LONDON: "centerOnLondon",
	// 					OVERVIEW: "overview",
	// 				},
	// 				entry: () => {
	// 					map.current.easeTo({ center: [103.78448, 1.29113] })
	// 				},
	// 			},
	// 			centerOnLondon: {
	// 				on: {
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 					CENTER_ON_HAMBURG: "centerOnHamburg",
	// 					CENTER_ON_DENMARK: "centerOnDenmark",
	// 					CENTER_ON_SINGAPORE: "centerOnSingapore",
	// 					OVERVIEW: "overview",
	// 				},
	// 				entry: () => {
	// 					map.current.easeTo({ center: [-0.07994, 51.51218] })
	// 				},
	// 			},
	// 			flyToHamburg: {
	// 				on: {
	// 					// ROTATE: "rotate",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 				},
	// 				entry: () => {
	// 					map.current.flyTo({
	// 						center: [10.00714, 53.58547],
	// 						zoom: 16,
	// 						bearing: 0,
	// 						essential: true,
	// 					})
	// 					// setActiveLocation(1)
	// 				},
	// 			},
	// 			flyToDenmark: {
	// 				on: {
	// 					// ROTATE: "rotate",
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 				},
	// 				entry: () => {
	// 					map.current.flyTo({
	// 						center: [12.57554, 55.73346],
	// 						zoom: 16,
	// 						bearing: 0,
	// 						essential: true,
	// 					})
	// 					// setActiveLocation(2)
	// 				},
	// 			},
	// 			flyToSingapore: {
	// 				on: {
	// 					// ROTATE: "rotate",
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_LONDON: "flyToLondon",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 				},
	// 				entry: () => {
	// 					map.current.flyTo({
	// 						center: [103.78448, 1.29113],
	// 						zoom: 16,
	// 						bearing: 0,
	// 						essential: true,
	// 					})
	// 					// setActiveLocation(3)
	// 				},
	// 			},
	// 			flyToLondon: {
	// 				on: {
	// 					// ROTATE: "rotate",
	// 					FLY_TO_HAMBURG: "flyToHamburg",
	// 					FLY_TO_DENMARK: "flyToDenmark",
	// 					FLY_TO_SINGAPORE: "flyToSingapore",
	// 				},
	// 				entry: () => {
	// 					map.current.flyTo({
	// 						center: [-0.07994, 51.51218],
	// 						zoom: 16,
	// 						bearing: 0,
	// 						essential: true,
	// 					})
	// 					// setActiveLocation(4)
	// 				},
	// 			},
	// 		},
	// 	}),
	// )

	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Say hello to us"
				subtitle="Let's have a coffee or two"
				backgroundImage={backgroundImage}
				translucent={true}
			/>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="gap-y-40">
						{officeLocations.features.map(
							({
								properties: {
									id,
									title,
									address,
									image,
									phone,
									className,
									state,
									onMouseOverState,
								},
							}) => (
								<div
									key={id}
									className={`col-span-12 md:col-span-6 xl:col-span-4 ${className}`}>
									{/* h-full xl:-m-20 xl:p-20 hover:xl:cursor-pointer hover:xl:bg-porcelain */}
									<div
										className={`flex flex-col items-start rounded-medium`}
										// onClick={() => {
										// 	send(state)
										// }}
										// onMouseOver={() => {
										// 	send(onMouseOverState)
										// }}
									>
										<GatsbyImage
											image={image}
											alt={title}
											title={title}
											className="w-full h-[220px] rounded-medium flex-none"
										/>
										<div className="grow xl:mx-20">
											<h3 className="display3 mt-20 mb-[14px]">{title}</h3>
											<div className="flex flex-row">
												<span className="mr-6 pt-4">
													<LocationMarker />
												</span>
												<p
													className="psmall"
													dangerouslySetInnerHTML={{ __html: address }}
												/>
											</div>
											{phone && (
												<div className="flex flex-row mt-10">
													<span className="mr-6 self-center">
														<Phone />
													</span>
													<p className="psmall">
														<a
															href={`tel:${phone}`}
															className="hover:text-pictonblue">
															{phone}
														</a>
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							),
						)}

						{/* <div className="hidden xl:block xl:col-span-6 row-span-2 xl:order-2 overflow-hidden">
							<div
								ref={mapContainer}
								className="bg-porcelain h-full min-h-[700px] rounded-medium overflow-hidden"></div>
						</div> */}
					</Grid>

					<Container className="my-spacer-md">
						<div className="bg-porcelain rounded-large py-60 flex flex-col items-center justify-center">
							<span className="text-pictonblue captionBtn text-center">Support</span>
							<h2 className="text-black mt-12 mb-30 text-center display3">
								You need help with your Cloud Fleet Manager applications?
							</h2>

							<div className="text-center">
								<Link to="/support">
									<Button primary={true} label="Contact support" />
								</Link>
							</div>
						</div>
					</Container>

					<BlueBanner
						className="my-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiContactPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}
		backgroundImage: file(relativePath: { eq: "contact.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		locationHamburgImage: file(relativePath: { eq: "office-hamburg.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationDenmarkImage: file(relativePath: { eq: "office-denmark.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationSingaporeImage: file(relativePath: { eq: "office-singapore.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationLondonImage: file(relativePath: { eq: "office-london.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationGreeceImage: file(relativePath: { eq: "office-greece.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
	}
`

export default ContactPage
